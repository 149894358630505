const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://adrienpichard.fr/',
  title: 'Welcome',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Adrien Pichard',
  role: 'MLOps Engineer',
  description: `
    As an MLOps engineer with expertise in Kubernetes, I have a strong understanding of distributed systems in GPU-accelerated environments.
    I am comfortable working on both private and public clouds providers.
  `,
  // resume: 'Adrien_PICHARD_resume_en.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/adrien-pichard/',
    github: 'https://github.com/a-pichard',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  // {
  //  name: 'Kleasy',
  //  description:
  //    'Kleasy is my master\'s degree innovative project that aims to create and automatically manage call workflows for enterprises. This project was validated by a jury and allowed me to obtain my Master\'s degree.',
  //  stack: ['K8s', 'Golang', 'gRPC', 'PostgreSQL', 'NextJS'],
  //  livePreview: 'https://kleasy.fr',
  // },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Git',
  'Golang',
  'C/C++',
  'CUDA Programming',
  'Python',
  'AWS',
  'GCP',
  'Linux',
  'Kubernetes',
  'Helm',
  'CI/CD',
  'Terraform',
  'Operator Framework',
  'MinIO',
  'Argo CD',
  'Argo Workflows',
  'Kubeflow Training Operator',
  'KServe',
  'BentoML and Yatai',
  'gRPC',
  'Prometheus',
  'Grafana',
  'Grafana Loki',
  'Grafana Mimir'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'contact@adrienpichard.fr',
}

export { header, about, projects, skills, contact }
